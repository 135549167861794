import * as React from 'react'

const BrandCardLine = ({brand, criteria, number}) => {
  const {cat, global, usability, logo, accessibility, seo, conception } = brand[1]
  
  return (
    <div className={`brand-card-line bg-${cat} ${brand[1]['evol_global'] ? '' : 'no-arrow'}`}>
      <div className="brand-card-line__inner flex items-center">
        <p className="knockout-title brand-card-line__number">{number}</p>

        <div className="brand-card-line__logo">
          <img src={`/images/logos/${logo}`} alt={brand[0]} />
        </div>

        <p className={`brand-card-line__note  ${brand[1]['evol_global'] ? `brand-card-line__note--${brand[1]['evol_global']}` : ''} ${criteria === 'global' ? 'is-active' : ''}`}><span className="sr-only">Index global</span>{global}</p>
        <p className={`brand-card-line__note ${brand[1]['evol_usability'] ? `brand-card-line__note--${brand[1]['evol_usability']}` : ''} ${criteria === 'usability' ? 'is-active' : ''}`}><span className="sr-only">Utilisabilité</span>{usability}</p>
        <p className={`brand-card-line__note ${brand[1]['evol_conception'] ? `brand-card-line__note--${brand[1]['evol_conception']}` : ''} ${criteria === 'conception' ? 'is-active' : ''}`}><span className="sr-only">Écoconception</span>{conception}</p>
        <p className={`brand-card-line__note ${brand[1]['evol_accessibility'] ? `brand-card-line__note--${brand[1]['evol_accessibility']}` : ''} ${criteria === 'accessibility' ? 'is-active' : ''}`}><span className="sr-only">Accessibilité</span>{accessibility}</p>
        <p className={`brand-card-line__note ${brand[1]['evol_seo'] ? `brand-card-line__note--${brand[1]['evol_seo']}` : ''} ${criteria === 'seo' ? 'is-active' : ''}`}><span className="sr-only">Efficacité SEO</span>{seo}</p>
      </div>
    </div>
  )
}

export default BrandCardLine