import React, {useState, useEffect, useRef} from 'react'
import {useEventListener} from 'react-recipes'

import Layout from '../../components/layout'
import SEO from '../../components/seo'
import Breadcrumb from '../../components/breadcrumb'
import CustomSelect from '../../components/customSelect'
import BrandCardLine from '../../components/brandCardLine'
import ContactSection from '../../components/contactSection'
import RankingCriterias from '../../components/rankingCriterias'

import orderBrands from '../../utils/orderBrands'
import criterias from '../../json/criterias.json'
import brands from '../../json/2021/brands.json'

const ClassementPage = ({location}) => {
  const [orderedBrands, setOrderedBrands] = useState(Object.entries(brands))
  const [currentCriteria, setCurrentCriteria] = useState('global')
  const [stickyButton, setStickyButton] = useState(false)
  const [offsetList, setOffsetList] = useState(0)
  const [heightList, setHeightList] = useState(0)
  let currentScore = useRef(0)

  const rankingList = useRef(null)

  // build options for custom selects (criteria + sector)
  let optionsCriteria = []

  Object.entries(criterias).forEach(criteria => {
    optionsCriteria.push({ 'value': criteria[0], 'label': criteria[1] })
  })

  const onChangeCriteria = (optionSelected) => {
    setCurrentCriteria(optionSelected.value)
    setOrderedBrands(orderBrands(optionSelected.value, 'all', '2021').orderedBrands)
    layerEvent(optionSelected.value)
  }

  const layerEvent = (value) => {
    window.dataLayer.push({
      'event': 'sort_ranking',
      'criteria': value
    })
  }

  const handleScroll = () => {
    if(heightList === 0) {
      setHeightList(rankingList.current.offsetHeight)
    }
    
    if(window.pageYOffset > offsetList && window.pageYOffset < offsetList + heightList - window.innerHeight) {
      setStickyButton(true)
    } else {
      setStickyButton(false)
    }
  }

  const handleCalc = (resize = true) => {
    if (rankingList.current) {
      setOffsetList(rankingList.current.offsetTop + 40)

      if(resize) {
        setHeightList(rankingList.current.offsetHeight)
      }
    }
  }

  useEffect(() => {
    const {orderedBrands} = orderBrands('global', 'all', '2021')
    setOrderedBrands(orderedBrands)
    setCurrentCriteria('global')
    currentScore.current = 0

    handleCalc(false)
  }, [rankingList])

  useEventListener('scroll', handleScroll)
  useEventListener('resize', handleCalc)

  return (
    <Layout year="2021" simpleHeader={true} url="/2021/classement" shareTxt="Découvrez le classement de l'Observatoire de l'impact positif !">
      <SEO 
        title="Classement complet de l'Observatoire de l'impact positif - 2021"
        description="Découvrez le classement intégral de l’Observatoire de l'impact positif des marques françaises : tendances en utilisabilité, SEO, écoconception et accessibilité en 2021."
        path="/2021/classement"
        year="2021" />

      <Breadcrumb url="/2021/classement" label="Classement" type="ranking" year="2021" />

      <div className="fluid-grid">
        {/* title + filter */}
        <div className="xl:flex justify-between items-end lg:pb-7">
          <h1 className="title-section title-section--ranking">Le classement complet</h1>
          <div className="custom-select-wrap custom-select-wrap--ranking flex-shrink-0 mt-4 xl:-mt-4">
            <label className="label-filter whitespace-nowrap flex-shrink-0 mb-1" htmlFor="criteriaLabel">Trier les résultats par :</label>
            <CustomSelect
              id="criteriaLabel"
              options={optionsCriteria}
              onChange={onChangeCriteria}
              onChangeMobile={(e) => {setCurrentCriteria(e.target.value); setOrderedBrands(orderBrands(e.target.value, 'all', '2021').orderedBrands); layerEvent(e.target.value)}}
              cssClass="is-index"
            />
          </div>

          <button onClick={() => window.scroll({top: 0, left: 0, behavior: 'smooth'})} aria-hidden="true"  className={`button button--full ranking-mobile-button ${stickyButton ? 'is-visible' : ''}`}><span className="icon"></span>Trier les résultats</button>
        </div>
        
        <div ref={rankingList} className="subsection subsection-ranking-list">
          <RankingCriterias currentCriteria={currentCriteria} />

          <ol className="ranking-list">
            { orderedBrands.map((brand, i) => {
              let hasNumber = true
              if (currentScore.current !== brand[1][currentCriteria]) {
                currentScore.current = brand[1][currentCriteria]
              } else {
                hasNumber = false
              }

              return (
                <li key={i} className="mb-2 md:mb-4">
                  <BrandCardLine brand={brand} criteria={currentCriteria} number={hasNumber ? i+1 : '-'} />
                </li>
              )
            })}
          </ol>
        </div>
      </div>

      <ContactSection />
    </Layout>
  )
  }

export default ClassementPage

