import * as React from 'react'

import CriteriaIcon from '../utils/criteriaIcon'

const RankingCriterias = ({currentCriteria}) => (
  <div className="hidden lg:flex justify-end ranking-criterias">
    <div className={`ranking-criterias__item ${currentCriteria === 'global' ? 'is-active' : ''}`}>
      {CriteriaIcon('global', 'mb-2 icon-old-pink')}
      <span className="label block">Index global</span>
    </div>
    <div className={`ranking-criterias__item ${currentCriteria === 'usability' ? 'is-active' : ''}`}>
      {CriteriaIcon('usability', 'mb-2 icon-old-pink')}
      <span className="label block">Utilisabilité</span>
    </div>
    <div className={`ranking-criterias__item ${currentCriteria === 'conception' ? 'is-active' : ''}`}>
      {CriteriaIcon('conception', 'mb-2 icon-old-pink')}
      <span className="label block">Écoconception</span>
    </div>
    <div className={`ranking-criterias__item ${currentCriteria === 'accessibility' ? 'is-active' : ''}`}>
      {CriteriaIcon('accessibility', 'mb-2 icon-old-pink')}
      <span className="label block">Accessibilité</span>
    </div>
    <div className={`ranking-criterias__item ${currentCriteria === 'seo' ? 'is-active' : ''}`}>
      {CriteriaIcon('seo', 'mb-2 icon-old-pink')}
      <span className="label block">Efficacité SEO</span>
    </div>
  </div>
)

export default RankingCriterias
